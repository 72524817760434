<template>
  <v-container fluid class="pt-0" style="font-family: sans-serif; font-size: .935rem;">
    <v-row justify="center">
      <v-col cols="12" md="6" sm="10" class="pt-10">
        <div class="title-wrapper">
          <div style="font-size: .9rem; font-weight: 500; padding-bottom: 8px;">Дата оновлення: 16.08.2021</div>
          <div style="font-size: 1.2rem; font-weight: bold; padding-bottom: 10px">Політика конфіденційності</div>
        </div>
        <v-divider></v-divider>
        <div class="body-wrapper">
          <div class="section">
            Ця політика конфіденційності ( далі - Політика ) регулює відносини між <strong>ТОВ “Вінксофт”</strong>, що
            зареєстрована та
            діє відповідно до законодавства України ( далі - “Компанія“, “Ми“) та вами (далі також – суб'єкт
            персональних даних), відносно збирання персональних даних про вас, їх використання і поширення Компанією у
            разі використання вами сервісу «WeToo», що розміщений на Сайті: wetoo.vinksoft-com.ua та
            wetoo-online.vinksoft.com.ua (далі – Веб-сайт)
          </div>
          <div class="section section-bold">
            Всім суб'єктам персональних даних необхідно ознайомитися із політикою конфіденційності, аби бути
            ознайомленим із умовами збору та використання персональних даних та мір безпеки, які використовуються.
          </div>
          <div class="section">
            Користуючись сервісом «WeToo», Ви приймаєте умови цієї Політики та даєте згоду на збір, використання та
            розкриття ваших персональні даних відповідно до цілей, що вказані нижче.
          </div>
          <div class="section">
            Цим ви також надаєте згоду на обробку своїх персональних даних та підтверджуєте, що є повідомлені про
            порядок, мету, а також інші умови їх обробки відповідно до ст. ст. 6, 8, 10, 11 Закону України «Про захист
            персональних даних» (далі - Закон).
          </div>
          <div class="section">
            Ви також визнаєте та підтверджуєте, що маєте достатню правоздатність та дієздатність за законами вашої
            країни для прийняття умов цієї Політики та надання згоди на обробку ваших персональних даних на умовах, що
            тут викладені. У випадку, якщо ви не маєте достатнього обсягу дієздатності, цим ви підтверджуєте, що згода з
            цими правилами надана вашими батьками або опікунами, що мають повний обсяг цивільної дієздатності та
            уповноважені за законами вашої країни надавати таку згоду.
          </div>
          <div class="section">
            Компанія може змінювати умови цієї Політики. У разі внесення таких змін, Ми, змінено дату останнього
            оновлення у верхній частині цієї публікації/сторінки/Політики.
          </div>
          <div class="section section-bold section-title">
            1. Визначення
          </div>
          <div class="section">
            <strong>Володілець персональних даних</strong> - фізична або юридична особа, яка визначає мету обробки
            персональних даних,
            встановлює склад цих даних та процедури їх обробки, якщо інше не визначено законом;
          </div>
          <div class="section">
            <strong>Згода суб'єкта персональних даних</strong> - будь-яке добровільне волевиявлення фізичної особи щодо
            надання дозволу на обробку її персональних даних відповідно до сформульованої мети їх обробки;
          </div>
          <div class="section">
            <strong>Обробка персональних даних</strong> - будь-яка дія або сукупність дій, здійснених повністю
            або частково в інформаційній (автоматизованій) системі та/або в картотеках персональних даних, які пов'язані
            зі збиранням, реєстрацією, накопиченням, зберіганням, адаптуванням, зміною, поновленням, використанням і
            поширенням (розповсюдженням, реалізацією, передачею), знеособленням, знищенням відомостей про фізичну особу;
          </div>
          <div class="section">
            <strong>Персональні дані</strong> - відомості чи сукупність відомостей про фізичну особу, яка ідентифікована
            або може бути конкретно ідентифікована;
          </div>
          <div class="section">
            <strong>Розпорядник бази персональних даних</strong> - фізична чи юридична особа, якій володільцем
            персональних даних або законом надано право
            обробляти ці дані від імені володільця;
          </div>
          <div class="section">
            <strong>Суб'єкт персональних даних</strong> - будь-яка фізична особа, у тому числі Відвідувач та/або
            Користувач, стосовно якої Компанією здійснюється обробка персональних даних;
          </div>
          <div class="section">
            <strong>Відвідувач</strong> - суб’єкт персональних даних, який здійснив вхід на сервіс «WeToo»;
          </div>
          <div class="section">
            <strong>Користувач</strong> - суб’єкт персональних даних, який здійснює реєстрацію та/або зареєстрував
            Обліковий запис у сервісі «WeToo».
          </div>
          <div class="section section-bold section-title">
            2. Права суб'єктів персональних даних
          </div>
          <div class="section">
            Особисті немайнові права на персональні дані, які має кожна фізична особа, є невід'ємними і непорушними.
          </div>
          <div class="section">
            <div>Суб'єкт персональних даних має право:</div>
            <div class="list-wrapper">
              <div class="list-element">
                знати про джерела збирання, місцезнаходження своїх персональних даних, мету їх
                обробки, місцезнаходження або місце проживання (перебування) володільця чи розпорядника персональних
                даних або дати відповідне доручення щодо отримання цієї інформації уповноваженим ним особам, крім
                випадків, встановлених законом;
              </div>
              <div class="list-element">
                отримувати інформацію про умови надання доступу до персональних даних, зокрема інформацію про третіх
                осіб, яким передаються його персональні дані;
              </div>
              <div class="list-element">
                на доступ до своїх персональних даних;
              </div>
              <div class="list-element">
                отримувати не пізніш як за тридцять календарних днів з дня надходження запиту, крім випадків,
                передбачених законом, відповідь про те, чи обробляються його персональні дані, а також отримувати зміст
                таких персональних даних;
              </div>
              <div class="list-element">
                пред’являти вмотивовану вимогу володільцю персональних даних із запереченням проти обробки своїх
                персональних даних;
              </div>
              <div class="list-element">
                пред'являти вмотивовану вимогу щодо зміни або знищення своїх персональних даних будь-яким володільцем та
                розпорядником персональних даних, якщо ці дані обробляються незаконно чи є недостовірними;
              </div>
              <div class="list-element">
                на захист своїх персональних даних від незаконної обробки та випадкової втрати, знищення, пошкодження у
                зв'язку з умисним приховуванням, ненаданням чи несвоєчасним їх наданням, а також на захист від надання
                відомостей, що є недостовірними чи ганьблять честь, гідність та ділову репутацію фізичної особи;
              </div>
              <div class="list-element">
                звертатися із скаргами на обробку своїх персональних даних до Уповноваженого або до суду;
              </div>
              <div class="list-element">
                застосовувати засоби правового захисту в разі порушення законодавства про захист персональних даних;
              </div>
              <div class="list-element">
                вносити застереження стосовно обмеження права на обробку своїх персональних даних під час надання згоди;
              </div>
              <div class="list-element">
                відкликати згоду на обробку персональних даних;
              </div>
              <div class="list-element">
                знати механізм автоматичної обробки персональних даних;
              </div>
              <div class="list-element">
                на захист від автоматизованого рішення, яке має для нього правові наслідки.
              </div>
            </div>
          </div>
          <div class="section section-bold section-title">
            3. Збір персональних даних та мета їх обробки
          </div>
          <div>У процесі своєї діяльності Компанія збирає наступні персональні дані користувача з відповідною метою:
          </div>
          <div class="list-wrapper">
            <div class="list-element">
              під час реєстрації: адреса електронної пошти, прізвище, ім’я та по-батькові
            </div>
            <div class="list-element">
              персональний кабінет (надає обслуговуюча компанія): мобільний телефон, адреса електронної пошти, прізвище,
              ім’я та по-батькові власника помешканя
            </div>
          </div>
          <div class="section section-bold section-title">
            4. Вік суб’єкта персональних даних
          </div>
          <div class="section">
            Реєструючись, відповідний суб’єкт персональних даних надає свою згоду та визнає, що він досяг віку 16 років
            і має всі права надавати Компанії згоду на обробку його персональних даних.
          </div>
          <div class="section">
            Якщо вам відомі випадки, коли Компанія обробляє дані особи, яка не досягла 16 років, будь ласка, повідомте
            про це, написавши на адресу електронної пошти: tovvinksoft@gmail.com
          </div>
          <div class="section section-bold section-title">
            5. Безпека персональних даних
          </div>
          <div class="section">
            Компанія вживає усіх заходів для забезпечення того, щоб будь-які персональні дані, якими володіє Компанія і
            за які вона несе відповідальність, зберігалися в безпеці і ні за яких умов не розкривалися, якщо тільки ця
            особа не була спеціально уповноважена Компанією отримувати таку інформацію і підписало угоду про
            конфіденційність, а також в інших передбачених законом України випадках
          </div>
          <div class="section">
            Суб’єкт персональних даних особисто несе відповідальність за наслідки розкриття персональних даних третім
            особам, які не уповноважені Компанією на отримання персональних даних і не підписали відповідну угоду про
            конфіденційність.
          </div>
          <div class="section">
            Компанія не несе відповідальності за випадкову втрату чи знищення персональних даних, що пов’язані з
            вразливістю програмного забезпечення чи телекомунікаційних каналів, які суб’єкт персональних даних
            використовує для передачі персональних даних Компанії.
          </div>
          <div class="section">
            Компанія залишає за собою право продати, передати чи іншим чином надати частину або всі персональні дані, у
            зв'язку зі злиттям, придбанням, реорганізацією або продажем активів Компанії, або у випадку банкрутства
            Компанії. У вас буде можливість відмовитися від будь-якої такої передачі, якщо планована обробка ваших
            персональних даних новим суб'єктом буде істотно відрізнятись від тієї, що вказана в цій Політиці.
          </div>
          <div class="section section-bold section-title">
            6. Період зберігання персональних даних
          </div>
          <div class="section">
            Компанія залишає за собою право зберігати зібрані і отримані персональні дані протягом 10 років з моменту
            останньої проведеної дії суб’єкта персональних даних в Обліковому записі сервісу «WeToo»
          </div>
          <div class="section">
            Компанія може продовжувати зберігати персональні дані, якщо подальша обробка передбачена законодавством для
            досягнення цілей суспільних інтересів, цілей наукового або історичного дослідження, або статистичних цілей.
          </div>
          <div class="section section-bold section-title">
            7. Зв’язок із компанією
          </div>
          <div class="section">
            Ви можете звернутись з запитаннями щодо цієї Політики в будь-який час за допомогою електронній пошті:
            tovvinksoft@gmail.com
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "privacy_policy"
}
</script>

<style scoped lang="scss">
.section {
  padding-top: 6px;
  padding-bottom: 6px;
}

.section-bold {
  font-weight: 600;
}

.section-title {
  font-size: 1.08rem;
}

.list-wrapper {
  margin-top: 8px;

  .list-element {
    margin-left: 8px;
    padding-top: 2px;
    padding-bottom: 4px;
    padding-right: 12px;
    font-style: oblique;
    display: flex;
    align-items: flex-start;

    &::before {
      content: '>';
      color: #2f8232;
      line-height: 2rem;
      font-size: 1.2rem;
      font-weight: bold;
      display: block;
      flex: 0 0 50px;
      margin-left: 10px;
    }
  }
}
</style>